/* Variables override */
@import 'variables';

/* Bootstrap import */
@import '../node_modules/bootstrap/scss/bootstrap.scss';

@import '~@ng-select/ng-select/themes/default.theme.css';
@import 'quill/dist/quill.core.css';
@import '~quill/dist/quill.snow.css';

body {
  margin-left: $sidebar-width;
  margin-bottom: $footer-height;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  color: $primary;
}

.rightModal {
  .modal-dialog {
    margin: 0px;
    margin-left: auto !important;

    .modal-content {
      border: 0px;
      border-radius: 0px;
      height: 100vh !important;
    }
  }
}

.cursor-pointer {
  cursor: pointer;
}

button.close {
  border: 0;
  background: transparent;
  display: inline;

  span {
    font-size: 35px;
  }
}

fa-icon {
  margin: 0 5px 0 5px;
}

.custom-control-input {
  position: absolute;
  z-index: -1;
  opacity: 0;
}

.checkmark {
  height: 18px;
  width: 18px;
  background-color: #fff;
  border: 2px solid $primary;
  border-top-color: $primary;
  border-right-color: $primary;
  border-bottom-color: $primary;
  border-left-color: $primary;
  border-radius: 5px;
  display: inline-block;
  color: white;
  text-align: center;
  font-size: 13px;
  font-weight: bold;
  box-sizing: content-box;
}

input {
  &:checked ~ .checkmark {
    background-color: $primary;
  }
}

.input-whith-icon {
  display: flex;
  flex-direction: row;
  align-items: center;
  .icon {
    margin: 0;
    position: relative;
    right: 2rem;
  }
  .icon:hover {
    cursor: pointer;
  }
}

.form-check-label {
  margin-left: 5px;
}

.custom-select {
  background-color: white;
  border: 1px solid $light;
  border-radius: 0.25rem;
  height: calc(1.5em + 0.75rem + 2px);
  vertical-align: middle;
  text-align: center;
  margin: 0.25rem;
  padding: 0.25rem;
}

.alert {
  display: grid;
  grid-template-columns: 95% 5%;
  align-items: center;
  padding: 13px;

  p {
    vertical-align: super;
    display: inline;
  }
}

.form-control {
  background-color: white !important;
}

.table > :not(:first-child) {
  border-top: 2px solid $primary;
}

.required-star {
  color: $primary;
}

.form-input-group {
  .text-danger {
    color: $primary;
    display: block;
  }
}

.button {
  border: solid 1px $primary;
  border-radius: 0.375rem;
  padding: 0.375rem 0.75rem;
  margin-right: 0.25rem !important;
  margin-left: 0.25rem !important;
  background-color: white;
  color: $primary;

  &.button-primary {
    color: white;
    background-color: $primary;
  }

  &:disabled {
    border-color: $secondary;
    color: $secondary;
    background-color: white;
  }

  &.button-small {
    padding: 0.1rem 0.75rem;
    margin: 0.15rem;
    border-radius: 0.2rem;
  }

  img {
    width: 40px;
  }
}

table {
  width: 100%;
  thead {
    color: $primary;
    border-bottom: solid 2px $primary;
  }

  tbody {
    tr:nth-of-type(2n + 1) {
      background-color: $body-bg;
    }
  }

  td {
    border-bottom: solid 1px $light;
  }
}

form {
  input {
    color: #212529;
    background-clip: padding-box;
    border: 1px solid $light;
    border-radius: 0.375rem;
    margin: 0.25rem;
    padding: 0.25rem;
  }

  [type='file'] + label {
    border: 1px solid $light;
    border-radius: 0.375rem;
    background-clip: padding-box;
    margin: 0.25rem;
    padding: 0.25rem;
    cursor: pointer;
    display: inline-block;
    text-align: center;
  }

  [type='file']:focus + label {
    outline: 1px solid;
  }

  label {
    padding: 0.25rem;
  }
}

.has-error {
  .ng-select-container {
    border-color: #a70000;
  }
}

.pagination-custom {
  display: grid;
  grid-template-columns: 15% auto 15%;
  margin-bottom: 1em;
  margin-top: 1em;
  align-items: center;

  #pages-selector {
    display: flex;
    justify-content: center;

    .page-selector {
      border-radius: 100px;
      border: $primary 1px solid;
      height: 36px;
      width: 36px;
      display: flex;
      justify-content: center;
      align-items: center;
      color: $primary;
      padding-bottom: 6px;

      cursor: pointer;
      margin: 0 10px;
      font-size: 2em;

      &.active {
        background-color: $primary;
        cursor: unset;
        color: white;
      }

      p {
        font-size: 0.7em;
        margin: -4px;
      }
    }
  }
}

.hover-link {
  cursor: pointer;
  // color: #0000ee; /* couleur de texte par défaut */
  text-decoration: none; /* supprime la décoration de texte par défaut */
}

.hover-link:hover {
  color: $primary !important; /* couleur de texte au survol */
  text-decoration: underline; /* ajoute une décoration de texte au survol */
}

.customTooltipClass .tooltip-inner {
  max-width: 450px;
  white-space: normal;
  font-size: 125%;
  padding-right: 1rem;
}
