/* Bootstrap variables - cf. https://github.com/twbs/bootstrap/blob/v4-dev/scss/_variables.scss  */
$primary: #d90404;
$secondary: #636363;
$success: #27a340;
$warning: #ff7700;
$danger: #a70000;
$light: #ced4da;

$body-bg: #f9f9f9;

/* App variables */
$sidebar-width: 110px;
$footer-height: 50px;

/* Loader */
$loader-overlay-bg: #676f6f;
$input-focus-bg: white;
